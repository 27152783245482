// Set brand colors
$white: #fff; 
$blue: #3498db;
$light-blue: #7EC1E2;
$dark-blue: #000B1A;
$green: #65ba47;
$light-green: #B4E0AE;
$dark-blue-border: #00234C;

// Path to Bulma's sass utility folder - override Bulma variables here
@use "../../node_modules/bulma/sass/utilities/index" with (
  $family-primary: '"Raleway", sans-serif',
  $primary: $green,
  $link: $blue,
  $control-border-width: 2px,
);

// Import the components we need
@use "../../node_modules/bulma/sass/base";
@use "../../node_modules/bulma/sass/helpers";
@use "../../node_modules/bulma/sass/layout/container";
@use "../../node_modules/bulma/sass/layout/level";
@use "../../node_modules/bulma/sass/components/navbar";
@use "../../node_modules/bulma/sass/components/pagination";
@use "../../node_modules/bulma/sass/grid/columns";
@use "../../node_modules/bulma/sass/layout/media";
@use "../../node_modules/bulma/sass/layout/section";
@use "../../node_modules/bulma/sass/elements/box";
@use "../../node_modules/bulma/sass/elements/button";
@use "../../node_modules/bulma/sass/elements/content";
@use "../../node_modules/bulma/sass/elements/image";
@use "../../node_modules/bulma/sass/elements/title";
@use "../../node_modules/bulma/sass/form";
@use "../../node_modules/bulma/sass/utilities/mixins";

@forward "../../node_modules/bulma/sass/themes";

// Import the Google Fonts
@import url(https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100..900;1,100..900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Oswald:wght@200..700&family=Raleway:ital,wght@0,100..900;1,100..900&display=swap);

// Custom styles
body {
  background-color: #000204;
  font-size: 1.15em;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

main {
  flex-grow: 1;
  display: flex;
}

a {
  --bulma-link-text: #65ba47;
}

.font-oswald {
  font-family: 'Oswald', sans-serif;
}

.wrapper {
  flex: 1 1 auto;
  @extend .bg-dark-blue, .border-dark-blue, .background-no-repeat;
}

.has-text-light-blue {
  color: $light-blue;
}
.has-text-light-green {
  color: $light-green;
}

.background-no-repeat {
  background-repeat: no-repeat;
}

.border-dark-blue {
  border-right: 1px solid $dark-blue-border;
  border-left: 1px solid $dark-blue-border;
}

.bg-cover-center {
  background-size: cover;
  background-position: center;
}

.bg-dark-blue {
  background-color: $dark-blue;
}

.page-background--default {
  background: rgb(0,11,26);
  background-image: url('/assets/images/hero-bg.jpg'); 
  background-image:
    linear-gradient(0deg, rgba(0,11,26,1) 0%, rgba(0,11,26,1) 60%, rgba(0,11,26,0) 100%),
    url('/assets/images/hero-bg.jpg');
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: top;
  background-attachment: scroll;
  position: relative;
  @media screen and (max-width: 768px) {
    background-image:
      linear-gradient(0deg, rgba(0,11,26,1) 0%, rgba(0,11,26,1) 85%, rgba(0,11,26,0) 100%),
      url('/assets/images/hero-bg.jpg');
    background-size: 768px auto; 
  }
}

.section h1 + p {
  padding: 0 2rem;
  @media screen and (min-width: 1024px) {
    padding: 0 3rem;
  }
  @media screen and (min-width: 1216px) {
    margin: 0 6rem;
    padding: 0 6rem;
  }
}

.section--services {
  @extend .bg-cover-center, .border-dark-blue, .background-no-repeat;
  background-image: url('/assets/images/services-bg.jpg');
  padding: 3rem 0 6rem;
}

.section--services {
  @media screen and (min-width: 769px) {
    ul {
      list-style-type: disc;
    }
    ul {
      margin: 0;
      padding-left: 20px;
    }
  }
}

// bot a bulma hero
.hero {
  padding: 6rem 2rem;
  width: 100%;
  text-align: center;
}

.hero-logo,
.south-river-image {
  height: auto;
}

.hero-logo {
  padding-top: 3.25rem;
  max-width: 400px;
}

// not a bulma footer
footer {
  @extend .bg-cover-center, .border-dark-blue, .background-no-repeat;
  background-image: url('/assets/images/footer-bg.jpg');
}

footer nav {
  display: flex;
  flex-direction: row;
  justify-content: center; 
  align-items: center;

  a {
    margin: 0 1em;
  }

  @media (max-width: 767px) {
    flex-direction: column;
    a:first-child {
      margin: 0 0 .5em;
    }
    a:last-child {
      margin: .5em 0 0;
    }
  }

}

.box-gradient-2 {
  background: linear-gradient(180deg, #99ccff, #548aac);
  @media screen and (min-width: 769px) {
    min-height: 250px;
  }
}

.reverse-columns-tablet {
  display: flex;
  flex-direction: column;

  @media screen and (min-width: 769px) {
    flex-direction: row-reverse;
  }
}

.image--quote {
  max-width: 300px;
}

.navbar {
  background-color: transparent;
  transition: background-color;
  @extend .border-dark-blue;
  &.has-background {
    background-color: $dark-blue;
  }
  &.is-active {
    background-color: $dark-blue; 
  }
  .navbar-brand {
    .navbar-item {
      text-transform: uppercase;
      h1 {
        color: $green;
      }
      span {
        color: $light-blue;
      }
      h1, span {
        transition-duration: var(--bulma-duration);
        transition-property: background-color, color;
      }
      &:hover,
      &:focus {
        background-color: transparent;
        h1, span {
          color: $white;
        }
      }
    }
  }

  .navbar-menu .navbar-item {
    color: $green;
    &:hover,
    &:focus {
      background-color: transparent;
      color: $white;
    }
  }
}

.button.is-srs {
  @extend .is-rounded, .has-text-white; 
  border: 2px solid $green;
  font-family: "Oswald", sans-serif;
  background-color: $dark-blue;
  border-color: $green; 
  &:hover {
    background-color: $green; 
  }
}